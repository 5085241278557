.pageHdn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settingForm {
    padding: 20px;
    background: #fff;
    border-radius: 15px;
}

.fdormGroup {
    position: relative;
    padding-bottom: 25px;
}

.fdormGroup label {
    font-size: 14px;
    color: #333;
    display: block;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.fdormGroup input {
    width: 100%;
    height: 50px;
    outline: 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 15px;
}

.fdormGroup select {
    width: 100%;
    height: 50px;
    outline: 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 15px;
}

.fdormGroup textarea {
    width: 100%;
    height: 100px;
    outline: 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 15px;
    resize: none;
}

.upload {
    width: 100%;
    height: 224px;
    border: 1px dashed #ccc;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload input {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.upload span {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #CCCCCC;
    font-weight: 600;
    font-size: 16px;
}

.upload span img {
    margin-bottom: 20px;
}

.image_container {
    width: 100%;
    height: 79px;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    margin-bottom: 10px;

}

.upload_image {
    width: 62px;
    height: 62px;
    border-radius: 5px;
    border: 1px solid #CCCCCC;
    object-fit: cover;
    overflow: hidden;
    justify-content: center;
    display: flex;
    align-items: center;

}

.upload_image_view {
    display: flex;
    margin: 10px;
    justify-content: space-between;
    align-items: center;
}

.image:hover {
    cursor: pointer;
}

.image_logo {
    object-fit: contain;
    height: 55px;
    width: 55px;
}