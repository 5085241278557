.invoice .exportBtn{
    padding: 8px 22px;
    border-radius: 5px;
}
.invoice .fltrfrm{
    width: calc(100% - 359px);
}
.invoice .frmGrp {
    width: calc(33% - 24px);
}
.thtbn{
    margin-right: 15px;
    background: #164FE6;
    color: #fff;
}
.thtbn:hover{
    background: #1543bd;
}
.invoice table thead th{
    white-space: nowrap;
}
.invoice table tbody td{
    white-space: nowrap;
}
.nodata-found-div{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color:#1543bd; */
  }