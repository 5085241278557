.login{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #F3F4F6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.loginfrm{
    width: 466px;
    text-align: center;
}
.login form{
    background: #fff;
    border-radius: 10px;
    padding: 25px;
}
.formGroup{
    width: 100%;
    position: relative;
    padding-bottom: 20px;
}
.formGroup label{
    font-size: 14px;
    color: #333;
    display: block;
    margin-bottom: 5px;
    text-align: left;
}
.formGroup input{
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 15px;
    outline: 0px;
    text-align: left;
}
.frmBtm{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
}
.frmBtm a{
    font-size: 14px;
    color: #164FE6;
    display: inline-block;
    margin-right: 15px;
}
.thnBtn{
    background: #164FE6;
    border: 0px;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 7px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    outline: 0px;
}
.thnBtn:hover{
    background: #0935a7;
}