header {
    padding: 0px 20px;
    background: #164FE6;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .user {
    display: flex;
    align-items: center;
    background: #002FAD;
    padding: 19px 20px;
}

header .user label {
    font-size: 16px;
    color: #fff;
    display: inline-block;
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 600;
}

.option-header span {
    font-size: 10px;
}

.option-header .option-text {
    font-size: 6px;
    color: #164FE6;
}

.list {
    position: relative;
}

.optionlist {
    position: absolute;
    margin-top: 90px;
    background-color: #fff;
}

.optionlist li {
    color: #002FAD;
    margin: 3px;
}

.optionlist li:hover {
    cursor: pointer;
    background-color: #fff;
    color: #002FAD;
}