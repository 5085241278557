.pageHdn h1{
 margin: 0px;
 color: #333;
 font-size: 18px;
 
}
.pageHdn{
    margin-bottom: 20px;
}
.pageHdn span{
    display: inline-block;
}
.pageHdn span a{
    color: #333;
    font-size: 16px;
    display: inline-block;
    padding: 0px 8px;
    text-decoration: none !important;
}
.pageHdn span a:first-child{
    padding-left: 0px;
}
.cardItem{  
    padding: 20px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 10px #ccc;
}
.cardItem:hover{
    cursor: pointer;
}
.cardTop span{
    display: inline-flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    background: rgb(22 79 230 / 13%);
    border-radius: 6px;
    margin-right: 15px;
}
.cardTop{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.cardTop b{
    font-size: 16px;
    display: block;
    font-weight: 600;
    color: #000;
}
.cardBtm h2{
    font-size: 16px;
    display: block;
    font-weight: 600;
    color: #000;
    margin: 0px;
}