.pageWapper{
    width: 100%;
    height: calc(100vh - 70px);
    padding: 20px 50px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0px;
    padding-right: 0px;
    overflow: hidden;
    background: #FAFBFE;
}
.menu{
    width: 275px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 19%);
    border-radius: 10px;
    border-bottom-left-radius:0px ;
    border-bottom-right-radius:0px ;
    background-image: url("../../assets/menuTop.png");
    background-position: top;
    background-repeat: no-repeat;
}
.wapper{
    width: calc(100% - 275px);
 
    height: calc(100% - 0px);
}
.mainwapper{
    height: calc(100% - 75px);
    overflow: auto;
    padding-right: 50px;
    padding-left: 20px;

}
.layout{
    background: #FAFBFE;
}
.themBtns{
    background: #164FE6;
    border: 0px;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    outline: none;
    padding: 10px 31px;
    transition: 0.3s;
}
.themBtns:hover{
    background: #2d61e9;
}