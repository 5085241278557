body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
*{
    box-sizing: border-box;
}
body{
    font-family: "Inter", sans-serif;
}
ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.notification_image{
  width: 30px;
  height: 30px;
  border-radius: 100px;
}
.heade-logout{
color: white;
width: 30px;
height: 25px;
margin: 10px;
}
.heade-logout:hover{
  cursor: pointer;
}
.banner_menu{


  color: #164FE6;  ;
  width: 14px;
  height: 14px;

}
.banner_lable{

  margin-left: 10px;
}
.nodata-found-div{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color:#1543bd; */
}

.switch {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 24px;
  background-color: #ccc;
  border-radius: 34px;
  position: relative;
  transition: background-color 0.3s;
  user-select: none; /* Prevent text selection */
}

.switch.on {
  background-color: #4caf50; /* Green color when ON */
}

.switch.off {
  background-color: #ccc; /* Grey color when OFF */
}

.switch-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
  transform: translateX(2px);
}

.switch.on .switch-handle {
  transform: translateX(25px); /* Move the handle to the right when ON */
}
