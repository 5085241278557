.ManageBusinessType .exportBtn{
    background: #164FE6;
    color: #fff;
    border-radius: 5px;
}
.ManageBusinessType .exportBtn:hover {
    background: #1543bd;
}
.nodata-found-div{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color:#1543bd; */
}