.logo{
    width: 100px;
    height: 100px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
    border-radius: 100%;
}
.logo a{
    display: inline-block;
}
.logo img{
    width: 100%;
}
nav ul li a{
    display: flex;
    font-size: 16px;
    color: #333;
    transition: 0.3s;
    padding: 5px 10px;
    text-decoration: none !important;
    align-items: center;
}

/* nav ul li i{
    display: flex;
    font-size: 16px;
    color: #333;
    transition: 0.3s;
    padding: 5px 10px;
    text-decoration: none !important;
    align-items: center;
} */
nav ul li a:hover{
    color: #164FE6;
}
nav ul li a.active{
    color: #164FE6;
}
nav ul li a img{    margin-right: 10px;}
.errorText{
    font-size: 12px;
    color: red;
}
.error_div{
    display: flex;
    align-items: flex-start;
    /* background-color: #164FE6; */
}