.fltrfrm {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 117px);
    gap: 24px;

}

.frmGrp {
    width: calc(25% - 24px);
}

.frmGrp input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: 0px;
    padding: 10px 15px;
    font-size: 14px;
    height: 40px;
}

.frmGrp select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: 0px;
    padding: 10px 15px;
    font-size: 14px;
    height: 40px;
}

.exportBtn {
    background: #FF2315;
    font-size: 16px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
    color: #fff;
    padding: 10px 34px;
    padding: 8px 34px;
    transition: 0.3s;
}

.exportBtn:hover {
    background: #e61b0c;
}

.comnFltr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.goBtn {
    background: #164FE6;
    color: #fff;
    border: 0px;
    font-size: 16px;
    cursor: pointer;
    width: 43px;
    height: 40px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.goBtn:hover {
    background: #0e43d6;
}

.flxFrm button {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.flxFrm {
    display: flex;
    align-items: center;
}

.flxFrm input {
    width: calc(100% - 43px);
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;

}

.comCard {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
}

.tableresponsive {
    overflow: auto;
    width: 100%;
}

table {
    width: 100%;
}

table thead th {
    text-align: left;
    color: #333;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    background: #EEF2F7;
    padding: 18px 15px;
}

.comnFltr {
    margin-bottom: 25px;
}

.tduser {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.inactive: {}

.tduser img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    object-fit: cover;
}

.tduser p {
    font-size: 14px;
    color: #989898;
    margin: 0px;
    width: calc(100% - 30px);
    padding-left: 15px;
    font-weight: 500;
}

table tr td {
    font-size: 14px;
    color: #989898;
    margin: 0px;
    font-weight: 500;
    padding: 10px 15px;
}

.prmn {
    color: #FF7A00;
}

td span.active {
    background: rgb(12 148 0 / 25%);
    color: #0C9400;
    border-radius: 10px;
    font-size: 12px;
    padding: 0px 7px;
}

td span.inactive {
    background: rgba(255, 5, 5, 0.993);
    color: #ffffff;
    border-radius: 10px;
    font-size: 12px;
    padding: 0px 7px;
}

.action a {
    display: inline-block;
    margin-right: 10px;
}

.action a:last-child {
    margin-right: 0px;
}

table tbody tr:nth-child(odd) td {
    background: #EEF2F7;
}

.tableFtr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tblFtrRyt button {
    display: flex;
    align-items: center;
}

.tblFtrRyt button i {
    margin: 0px 5px;
}

.tblFtrRyt button {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    background: #fff;
    text-decoration: none;
    border-radius: 100%;
    border: none;
}

.tblFtrRyt button hover {
    background: #164FE6;
    color: #fff;
}

.tblFtrRyt button.active {
    background: #164FE6;
    color: #fff;
}

.tblFtrLft label {
    font-size: 16px;
    color: #000;
    display: inline-block;
    margin: 0px;
    margin-right: 10px;
}

.tblFtrLft select {
    height: 27px;
    border-radius: 5px;
    font-size: 13px;
    padding: 2px 10px;
    border: 1px solid #ccc;
    outline: 0px;
}

.tableFtr {
    margin-top: 50px;
}

.checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.checkbox label {
    position: relative;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 2px;
}

.checkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #ccc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    width: 16px;
    height: 16px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.checkbox input:checked+label:before {
    background: #164FE6;
    border: 1px solid #164FE6;
}

.checkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.flxtd {
    display: flex;
    align-items: center;
}

.comCard table tr th:first-child {
    width: 40px;
    padding-right: 0px;
}

.nodata-found-div {
    display: flex;
    justify-content: center;
    align-items: center;
}